import { Box, Typography, Grid, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import * as routes from "@/constants/routes";
import useTranslation from "next-translate/useTranslation";
import { countryOptions } from "@/constants/countryConstants";
import { ReactComponent as Arrow } from "@/assets/arrowRight.svg";

interface ICountryRunsProps {
    topic: string;
    featuredRuns: any;
    handleClose: VoidFunction;
}

const classes = {
    countryTitle: {
        cursor: "pointer",
        color: "#000",
        "&:hover": {
            color: "#ff8640",
        },
    },
    countryMainTitle: {
        cursor: "pointer",
        pb: 1.5,
        pl: 3,
        "&:hover": {
            color: "#ff8640",
        },
        "&:active": {
            color: "#ff8640",
        },
    },
    countrySubtitle: {
        ml: 4,
        color: "#525252",
    },
};

export const GetCorrectFlag = (country: string) => {
    const countryIndex = countryOptions.options.findIndex((e: any) => e.optionId === country);
    if (countryIndex !== -1) {
        return countryOptions.options[countryIndex].code?.toLowerCase();
    } else {
        return "";
    }
};

const CountryRuns = (props: ICountryRunsProps) => {
    const [country, setCountry] = useState<any>(
        props.featuredRuns?.country !== undefined
            ? Object.keys(props.featuredRuns?.country)[0]
            : "Denmark"
    );
    const [ready, setReady] = useState(false);
    const { t } = useTranslation("translation");
    const router = useRouter();
    let timer: any = 0;
    const translationCounty: any = t("findRace.search.county", {}, { returnObjects: true });
    const county: any = {
        ...countryOptions,
        ...translationCounty,
    };

    useEffect(() => {
        if (props.featuredRuns?.country) {
            setReady(true);
            setCountry(Object.keys(props.featuredRuns?.country)[0]);
        }
    }, [props.featuredRuns]);

    const handleMouseEnter = (new_country: string) => {
        timer = setTimeout(() => {
            setCountry(new_country);
        }, 500);
    };

    const handleMouseLeave = () => {
        clearTimeout(timer);
    };

    const handleClick = (new_country: string) => {
        router.push({ pathname: routes.FIND_RUN, query: { country: new_country, county: "Alle" } });
        props.handleClose();
    };

    const renderHeaders = (dict: any, country: string) => {
        const componentList = [];

        const translationCounty: any = t("findRace.search.county", {}, { returnObjects: true });
        const county: any = {
            ...countryOptions,
            ...translationCounty,
        };

        for (const key in dict) {
            //const index = county.options.findIndex((e: any) => e.optionId === key);

            const numberOfEvents = Object.values(dict[key]).reduce((a: any, b: any) => a + b, 0);

            componentList.push(
                <Box
                    key={key}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        cursor: "pointer",
                    }}
                    onMouseEnter={() => handleMouseEnter(key)}
                    onMouseLeave={() => handleMouseLeave()}
                    onClick={() => handleClick(key)}
                >
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box
                            component="img"
                            alt={key}
                            sx={{ width: "18px", height: "18px", borderRadius: "25px", mr: -1 }}
                            src={`https://flagcdn.com/w40/${GetCorrectFlag(key)}.png`}
                        />
                        <Typography
                            variant="body1"
                            key={key}
                            sx={{
                                ...classes.countryMainTitle,
                                color: key === country ? "#ff8640" : "",
                            }}
                        >
                            {county.textOptions[key] ?? key}
                            {" ( "}
                            {numberOfEvents}
                            {" ) "}
                        </Typography>
                    </Box>
                    <Arrow width={15} stroke="black" />
                </Box>
            );
        }

        return componentList;
    };

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    direction: "row",
                    alignItems: "flex-start",
                }}
            >
                {ready && (
                    <>
                        <Box width={"300px"} px={3} sx={{ overflow: "auto" }} height={"440px"}>
                            <Typography variant="h6" color="#ff8640" pt={1} pb={2}>
                                {t("components.navigation.eventTopics.0")}
                            </Typography>
                            {props.featuredRuns?.country &&
                                renderHeaders(props.featuredRuns.country, country)}
                        </Box>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Box width={"300px"} px={3} sx={{ overflow: "auto" }} height={"440px"}>
                            <Typography variant="h6" color="#ff8640" pt={1} pb={2}>
                                {county.textOptions[country]}
                            </Typography>
                            {props.featuredRuns?.country &&
                                CountyItems(
                                    props.featuredRuns.country[country],
                                    country,
                                    props.handleClose,
                                    t
                                )}
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default CountryRuns;

const CountyItems = (dict: any, country: string, handleClose: VoidFunction, t: any) => {
    return (
        <>
            <Box>{renderItems(dict, country, handleClose, t)}</Box>
        </>
    );
};

const renderItems = (dict: any, country: string, handleClose: VoidFunction, t: any) => {
    const componentList = [];
    const numberOfEvents = Object.values(dict).reduce((a: any, b: any) => a + b, 0);
    componentList.push(
        <SubItem
            key="Alle"
            title={t("blogPage.seeAll")}
            county={"Alle"}
            numbers={numberOfEvents}
            country={country}
            handleClose={handleClose}
        />
    );
    for (const key in dict) {
        componentList.push(
            <SubItem
                key={key}
                title={key}
                county={key}
                numbers={dict[key]}
                country={country}
                handleClose={handleClose}
            />
        );
    }
    return componentList;
};

const SubItem = (props: any) => {
    const router = useRouter();
    const handleClick = () => {
        let county_url = props.county !== "Alle" ? ("/" + props.county):"";
        router.push({
            pathname: routes.FIND_RUN + "/" + props.country + county_url,
            // query: { country: props.country, county: props.county },
        });
        props.handleClose();
    };

    return (
        <Box
            sx={{
                ...classes.countryTitle,
                color: props.county === "Alle" ? "#000" : "#000",
                display: "flex",
                flexDirection: "row",
                pb: "11px",
            }}
        >
            <Typography
                sx={{ ...classes.countryTitle, color: props.county === "Alle" ? "#000" : "#000" }}
                onClick={handleClick}
            >
                {props.title}
                {" ( "}
                {props.numbers}
                {" ) "}
            </Typography>
        </Box>
    );
};
