import firebaseTriggerUrls from "../../constants/firebaseTriggers";
import axios from "axios";

async function HandleFriendRequest(
  friendUsername: string,
  friendRequestAction: boolean,
  user: any
): Promise<any> {
  const URL = firebaseTriggerUrls.HandleFriendRequest;
  const token = await user.auth.currentUser?.getIdToken();

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  const data: any = {
    username: friendUsername,
    action: friendRequestAction ? "add" : "delete",
  };

  const promise = axios({
    headers: headers,
    url: URL,
    method: "post",
    timeout: 4000,
    data: data,
  });

  const response = promise.then((response: any) => response).catch((e) => e);
  return response;
}

export { HandleFriendRequest };
