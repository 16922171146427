import firebaseTriggerUrls from "../constants/firebaseTriggers";
import axios from "axios";

async function GetAllUsersRequest(searchText: string): Promise<any> {
  const URL = firebaseTriggerUrls.searchUsers;

  const headers = {
    Accept: "application/json",
  };

  const data = {
    "name": searchText
  };

  const promise = axios({
    headers: headers,
    url: URL,
    method: "post",
    data: data,
    timeout: 2000,
  });

  const response = promise
    .then((response: any) => response)
    .catch(() => ({ status: 444 }));
  return response;
}

async function GetSuggestedUsersRequest(friends: string): Promise<any> {
  const URL = firebaseTriggerUrls.suggestedFriends + "?users=" + friends;

  const headers = {
    Accept: "application/json",
  };

  const promise = axios({
    headers: headers,
    url: URL,
    method: "post",
    timeout: 2000,

  });

  const response = promise
    .then((response: any) => response)
    .catch(() => ({ status: 444 }));
  return response;
}

async function getListOfUsers(searchList: string[]): Promise<any> {
  const URL = firebaseTriggerUrls.getListOfUsers;

  const headers = {
    Accept: "application/json",
  };


  const promise = axios({
    headers: headers,
    url: URL,
    method: "post",
    data: searchList,
    timeout: 4000,
  });

  const response = promise
    .then((response: any) => response)
    .catch(() => ({ status: 444 }));
  return response;
}

async function getUserData(user_id: string, token:any, masterclass=false): Promise<any> {
  const URL = process.env.NEXT_PUBLIC_DEVELOPMENT === "development" ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_user_information" : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_user_information";
  const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
  };

  const data = {
    "user_id": user_id,
    "fetch_masterclass": masterclass
  };

  const promise = axios({
    headers: headers,
    url: URL,
    method: "post",
    data: data,
    timeout: 3000,
  });

  const response = promise
    .then((response: any) => response)
    .catch(() => ({ status: 444 }));
  return response;
}

export { GetAllUsersRequest, getListOfUsers, GetSuggestedUsersRequest, getUserData };
