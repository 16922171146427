import { useState, useEffect } from "react";
import { ListItem, ListItemText, ListItemAvatar, Avatar, Button, Box, Grid, Typography } from "@mui/material";
import FriendCardProps from "../interfaces/friendCardInterface";
import { withFirebase } from "@/components/Firebase";
import { SendFriendRequest } from "@/HttpCalls/Friends/sendFriendRequest";
import { HandleFriendRequest } from "@/HttpCalls/Friends/handleFriendRequests";


import {ReactComponent as Friends} from '@/assets/friends.svg';
import {ReactComponent as FriendsAdd} from '@/assets/personAdd.svg';

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import { GetCorrectFlag } from "@/components/SubMenu/countryRuns";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import Link from "next/link";

const FriendCard = (props: FriendCardProps) => {
    const { t } = useTranslation("translation");
    const [showButton, setShowButton] = useState(true);
    const [showPendingButton, setShowPendingButton] = useState(false);
    const [showHandleFriendRequest, setShowHandleFriendRequest] = useState(false);
    const [hide, setHide] = useState(false);
    const [text, setText] = useState(t("findFriends.friendCard.friend") as string);

    const router = useRouter();

    const translationCountries: any = t("findRace.search.county", {}, { returnObjects: true });

    const classes = {
        btn: {
            textTransform: "none",
            fontSize: "14px",
            lineHeight: "18px",
            float: "right",
        },
        btn_follow: {
            textTransform: "none",
            fontSize: "14px",
            minWidth: "130px",
            maxWidth: "130px",
            float: "right",
        },
        link: {
            display: "flex",
            color: "rgb(190, 86, 24)",
            "&:hover": {
                color: "black",
                textDecoration: "none",
            },
        },
        avatar: {
            paddingTop: "7px",
            height: "60px"
        },
        item: {
            paddingLeft: "0px",
            py: 1,
        },
        flag: {
            position: "relative",
            top: "-20px",
            left: "30px",
        },
    };

    useEffect(() => {
        if (props.firebase.currentUser?.friends !== undefined) {
            if (
                props.firebase.currentUser?.friends
                    .split(";")
                    .find((element: string) => element === props.username)
            ) {
                setShowButton(false);
            } else if (props.firebase.currentUser?.pendingFriendRequests !== undefined) {
                if (
                    props.firebase.currentUser?.pendingFriendRequests
                        .split(";")
                        .find((element: string) => element === props.username)
                ) {
                    setShowButton(false);
                    setShowPendingButton(true);
                    setText(t("findFriends.friendCard.sendt"));
                }
            }
        }
        if (props.firebase.currentUser?.username === props.username) {
            setShowButton(false);
            setShowPendingButton(true);
            setText(t("findFriends.friendCard.friend"));
        }

        if (props.firebase.currentUser?.friendRequests !== (undefined && "")) {
            if (
                props.firebase.currentUser?.friendRequests
                    .split(";")
                    .find((element: string) => element === props.username)
            ) {
                setShowHandleFriendRequest(true);
                setShowButton(false);
                setShowPendingButton(false);
            }
        }
    }, [props.firebase.currentUser, props.username, t]);

    const sendFriendRequest = async () => {
        if (props.recommended) {
            setHide(true);
        }
        setShowHandleFriendRequest(false);
        setShowButton(false);
        setText(t("findFriends.friendCard.sendt"));
        const response = await SendFriendRequest(props.username, props.firebase);
        const data = {
            data: {
                friend_username: props.username,
                username: props.firebase.currentUser.username,
            },
            activityType: 0,
            owner: props.firebase.currentUser.username,
        };
        props.firebase.addNewActivity(data);
        if (response.status === 200) {
            props.firebase.tagManagersArgs["dataLayer"]["event"] = "add_friend";
            props.firebase.tagManagersArgs["dataLayer"]["user_num_friends_pending"] += 1;
            props.firebase.logFirebaseEvent(props.firebase.tagManagersArgs);
            props.firebase.logFirebaseEvent(null, "SendtFriendRequest", true, "users");
            props.firebase.updateOnCurrentUser();
            setShowHandleFriendRequest(false);
            setShowButton(false);
            setText(t("findFriends.friendCard.sendt"));
        } else if (response.status === 400) {
            setText(t("findFriends.friendCard.friend"));
            setShowButton(true);
            console.log("Noe gikk galt");
        } else {
            console.log("Noe uventet skjedde");
        }
    };

    const handleFriendRequest = async (friendRequestAction: boolean) => {
        if (props.alertView && props.markAsread) {
            props?.markAsread();
        }
        setShowHandleFriendRequest(false);
        setShowButton(false);
        const response = await HandleFriendRequest(
            props.username,
            friendRequestAction,
            props.firebase
        );
        if (friendRequestAction) {
            const data = {
                data: {
                    friend_username: props.username,
                    username: props.firebase.currentUser.username,
                },
                activityType: 1,
                owner: props.firebase.currentUser.username,
            };
            props.firebase.addNewActivity(data);
            props.firebase.tagManagersArgs["dataLayer"]["event"] = "accept_friend";
            props.firebase.tagManagersArgs["dataLayer"]["user_num_friends"] += 1;
            props.firebase.logFirebaseEvent(props.firebase.tagManagersArgs);
            props.firebase.logFirebaseEvent(null, "AcceptedFriendRequest", true, "users");
        } else {
            props.firebase.logFirebaseEvent(null, "DeniedFriendRequest", true, "users");
        }
        if (response.status === 200) {
            props.firebase.updateOnCurrentUser();
            setShowHandleFriendRequest(false);
            setShowButton(false);
        } else if (response.status === 400) {
            setShowButton(true);
            console.log("Noe gikk galt");
        } else {
            console.log("Noe uventet skjedde");
        }
    };

    const VisitUser = () => {
        router.push("/profile/" + props.username);
    };

    return (
        <Box component="div" key={props.username}>
            {!hide && (
                <ListItem
                    sx={{ ...classes.item, maxWidth: props.alertView ? "340px" : "auto" }}
                    disableGutters
                >
                    <Grid container>
                        <Grid
                            item
                            xs={props.alertView ? 12 : 10}
                            md={props.alertView ? 12 : 8}
                            onClick={() => {
                                if (props.friendList) props.friendList();
                            }}
                        >
                            <Link href={"/profile/" + props.username} style={classes.link}>
                                <ListItemAvatar sx={classes.avatar}>
                                    <Avatar>
                                        <Avatar src={props.photoURL} key={props.photoURL} />
                                    </Avatar>
                                    {translationCountries.textOptions[props.country] && (
                                            <Box
                                                component="img"
                                                src={`https://flagcdn.com/w40/${GetCorrectFlag(
                                                    props?.country
                                                )}.png`}
                                                height="18px"
                                                width="18px"
                                                borderRadius="25px"
                                                sx={classes.flag}
                                            />
                                        )}
                                </ListItemAvatar>

                                <ListItemText 
                                    primary={
                                        props?.firstName !== undefined && props.firstName !== ""
                                            ? props?.firstName + " " + props?.lastName
                                            : t("findFriends.friendCard.namePlaceholder")
                                    }
                                    secondary={getPlaceAndCountry(
                                        props.place,
                                        props.country,
                                        translationCountries
                                    )}
                                />
                            </Link>
                        </Grid>
                        <Grid item xs={props.alertView ? 12 : 2} md={props.alertView ? 12 : 4}>
                            {showButton ? (
                                props.firebase.currentUser?.username ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={sendFriendRequest}
                                        sx={classes.btn_follow}
                                        startIcon={<FriendsAdd width={18} fill="#ff8640"/>}
                                    >
                                        <Box sx={{ display: { xs: "none", md: "block" } }}>
                                            {t("findFriends.friendCard.add")}
                                        </Box>
                                    </Button>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={classes.btn_follow}
                                        startIcon={<FriendsAdd width={18} fill="#ff8640"/>}
                                        onClick={() => props.handleNoneAuthAction()}
                                    >
                                        <Box sx={{ display: { xs: "none", md: "block" } }}>
                                            {t("findFriends.friendCard.add")}
                                        </Box>
                                    </Button>
                                )
                            ) : showPendingButton ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={classes.btn}
                                    startIcon={<PersonIcon />}
                                    onClick={VisitUser}
                                >
                                    <Box sx={{ display: { xs: "none", md: "block" } }}> {text}</Box>{" "}
                                </Button>
                            ) : (
                                !showHandleFriendRequest && (
                                    <Button
                                        sx={classes.btn}
                                        startIcon={<Friends width={18} fill="black" />}
                                        onClick={VisitUser}
                                    >
                                        <Typography variant="body1" sx={{ display: { xs: "none", md: "block" }, color:'black', fontWeight: 400}}>
                                            {text}
                                        </Typography>
                                    </Button>
                                )
                            )}

                            {showHandleFriendRequest ? (
                                <Box sx={{ display: props.alertView ? "flex" : "block" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ textTransform: "none", width: "100%" }}
                                        startIcon={<CheckIcon />}
                                        onClick={() => handleFriendRequest(true)}
                                    >
                                        <Box sx={{ display: { xs: "none", md: "block" } }}>
                                            {t("findFriends.friendCard.accept")}
                                        </Box>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{ textTransform: "none", width: "100%" }}
                                        startIcon={<CloseIcon />}
                                        onClick={() => handleFriendRequest(false)}
                                    >
                                        <Box sx={{ display: { xs: "none", md: "block" } }}>
                                            {t("findFriends.friendCard.reject")}
                                        </Box>
                                    </Button>
                                </Box>
                            ) : null}
                        </Grid>
                    </Grid>
                </ListItem>
            )}
        </Box>
    );
};

export default withFirebase(FriendCard);

export function getPlaceAndCountry(place: any, country: any, translationCountries: any) {
  const placeText = place || "";
  const separator = place &&
		    translationCountries.textOptions[country] ?
		    ", " : "";
  
  const countryText = translationCountries.textOptions[country] ?
		      `${translationCountries.textOptions[country]}` : "";

    const result = placeText +  separator + countryText;
    return <Typography variant="body1" color="black">{result}</Typography>;
}
